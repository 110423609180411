<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="id ? '编辑聚合页' :'创建聚合页'"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
 
      <!-- 活动标题 -->
      <a-form-item
        label="内容主题"
        name="title"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.title"
        />
      </a-form-item>
      <!-- banner -->
      <a-form-item
        label="banner"
        name="banner_urls"
      >
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="NewFilmDrawer"
          :fileNumber="5"
          :imgSizeMode="3"
          :imgSizeWidth="670"
          :imgSizeHeight="332"
          imgSizeError="图片尺寸为 670 * 332"
          :beforeUploadPro="(file) => { return beforeUploadPro(file, 'banner_urls') }"
          :fileList="formState.banner_urls"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.png .jpg .gif，尺寸为 670x332。二级页面会调用该图片</div>
        <div class="form-upload-list" v-if="formState.banner_urls.length">
          <div
            class="form-upload-item"
            v-for="(item, index) in formState.banner_urls"
            :key="index"
          >
            <ImageView
              class="upload-image upload-image-horizontal"
              :url="$pub.CDN_URL(item.url)"
              :fileJson="item"
              @delete="touchDeleteCover('banner_urls', index)"
            >
            </ImageView>
          </div>
        </div>
      </a-form-item>
      <!-- 活动标题 -->
      <a-form-item
        label="广告解锁"
        name="aggregation_ad_unlock"
      >
        <a-switch
          v-model:checked="formState.aggregation_ad_unlock"
          checked-children="开"
          un-checked-children="关"
        />
      </a-form-item>
      <!-- 解锁全集价格 -->
      <a-form-item
        label="解锁全集价格"
        name="aggregation_price"
        :rules="[{ validator: validatorMoney }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.aggregation_price"
          suffix="元"
        />
      </a-form-item>
      <!-- 短剧 -->
      <a-form-item
        name="drama_ids"
        label="短剧"
      >
        <!-- 短剧菜单 -->
        <div class="drama-menu">
          <a-form-item v-for="item in dramas" :key="item.id">
            <a-checkbox
              v-model:checked="item.select"
            >
              {{ item.remark_name }}
            </a-checkbox>
          </a-form-item>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Upload from '@/components/Upload'
import Loading from '@/components/Loading'
import ImageView from '@/components/ImageView'
import { aggregationPageSave, dramaAll } from '@/api/copyright'
import Pub from '@/utils/public'
import dayjs from 'dayjs'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 详情
let detail = ref({})
// 短剧列表
let dramas = ref([])
// 表单
let formState = reactive({
  // 活动标题
  title: undefined,
  // banner图
  banner_urls: [],
  banner_url: '',
  // 广告解锁
  aggregation_ad_unlock: 0,
  // 全集解锁价格
  aggregation_price: undefined,
  //短剧ids
  drama_ids: [],
  // 活动说明
  activity_description: undefined
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  detail.value = params
  visible.value = true
  // 赋值
  nextTick(() => {
    if (params) {
      // id
      id.value = params.id
      formState.title = params.title
      formState.drama_ids = params.drama_ids
      formState.aggregation_ad_unlock = Boolean(params.aggregation_ad_unlock)
      formState.aggregation_price = params.aggregation_price === '0.00' ? undefined : params.aggregation_price
      if (params.banner_url) {
        formState.banner_url = params.banner_url
        formState.banner_urls = [{
          url: params.banner_url
        }]
      } else {
        formState.banner_url = ''
        formState.banner_urls = []
      }
    }
  })
  getDramaAll()
}


// 短剧列表
function getDramaAll () {
  dramaAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
      dramas.value.forEach(i => {
        formState.drama_ids.forEach(j => {
          if (i.id === j) {
            i.select = true
          }
        })
      })
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    id.value = undefined
    visible.value = false
  }
}

// 准备上传（图片）
function beforeUploadPro (file, key) {
  if (!file.type.includes('image')) {
    message.error('请上传正确的图片类型文件')
    return false
  }
  // 清空列表
  if (key) { formState[key] = [] }
  // 允许
  return true
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}

// 提交
function touchSubmit () {
  let ids = []
  let num = 0
  dramas.value.forEach(i => {
    if (i.select) {
      ids.push(i.id)
    }
  })
  if (ids.length === 0) {
    message.error('请选择短剧')
    return
  }
  RefForm.value.validate().then(() => {
    isLoading.value = true
    formState.drama_ids = ids
    if (formState.banner_urls.length > 0) {
      formState.banner_url = formState.banner_urls[0].url
    } else {
      formState.banner_url = ''
    }
    const params = {
      id: id.value,
      title: formState.title,
      aggregation_ad_unlock: Number(formState.aggregation_ad_unlock),
      aggregation_price: (formState.aggregation_price || 0) * 1000 / 10,
      banner_url: formState.banner_url,
      drama_ids: formState.drama_ids,
    }
    aggregationPageSave(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        if (id.value) {
          message.success('更新成功')
        } else {
          message.success('创建成功')
        }
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 效验 - 价格
function validatorMoney (rule, value) {
  if (!value) {
    // return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_INTEGER_OR_FLOAT(value)) {
    return Promise.reject('必须为正数，且最多保留两位小数')
  }
  return Promise.resolve()
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 118px;
}
.image-view.upload-image {
  height: 148px;
}
.image-view.upload-image-horizontal {
  width: 208px;
  height: 124px;
}
.desc-textarea {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
}

.drama-menu {
  display: flex;
  padding: 6px 0 0 6px;
  flex-wrap: wrap;
  .ant-form-item {
    margin-right: 6px;
  }
}
</style>